import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import styled from "styled-components";
import { ProgramCard } from "../programCard";

const fields = [
  {
    type: "file",
    label: "Upload icon",
    name: "icon",
  },
  {
    type: "text",
    label: "Program Title",
    placeholder: "Program Title",
    name: "title",
  },
  {
    type: "text",
    label: "Program Copy",
    placeholder: "Program Copy",
    name: "copy",
  },
  {
    type: "text",
    label: "Insert into position",
    placeholder: "Insert into position",
    name: "position",
  },
];

export const AdminProgrammes = () => {
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query programQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            programs {
              copy
              title
              icon
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { programs } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(programs);
  }, [data]);

  const handleNew = ({ icon, copy, title, position }) => {
    console.log(position);
    if (icon && copy && title) {
      const newData = {
        icon,
        copy,
        title,
      };
      setUploaded([...uploaded, newData]);
      if (!!position) {
        const index = Number(position) - 1;
        const newArray = [...combinedData];
        newArray.splice(index, 0, newData);
        setCombinedData(newArray);
      } else {
        setCombinedData([...combinedData, newData]);
      }
    }
  };

  const handleRemove = (title) => {
    const array = combinedData;
    setCombinedData(array.filter((item) => item.title !== title));
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const toUpload = combinedData.map(({ icon, copy, title }) => {
      return {
        icon,
        copy,
        title,
      };
    });
    adminUpload("programs", toUpload);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const ProgramGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  `;

  return (
    <>
      <h1>Programmes</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNew} />
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <ProgramGrid>
          {combinedData.map(({ icon, copy, title }, i) => (
            <div>
              <h3>Position: {i + 1}</h3>
              <ProgramCard icon={icon} copy={copy} title={title} />
              <Container center>
                <Spacer height={16} />
                <button onClick={() => handleRemove(title, "published")}>
                  Delete
                </button>
              </Container>
            </div>
          ))}
        </ProgramGrid>
      </Container>
    </>
  );
};
