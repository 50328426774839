import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";

export const Guides = () => {
  const [guides, setGuides] = useState([]);

  const data = useStaticQuery(graphql`
    query adminGuidesQuery {
      allGoogleGuidesSheet {
        edges {
          node {
            link
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    const guides = data?.allGoogleGuidesSheet?.edges?.map(
      ({ node: { link, title } }) => {
        return {
          link,
          title,
        };
      }
    );
    setGuides(guides);
  }, [data]);

  const DisplayTable = ({ items, uploaded }) => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items?.map(({ title, link }, i) => (
          <Table.Row key={i}>
            <Table.Cell>{title}</Table.Cell>
            <a href={link} target="_blank" rel="noreferrer">
              <Table.Cell>{link}</Table.Cell>
            </a>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <h2>Video guides to using the admin section</h2>
      <DisplayTable items={guides} />
    </>
  );
};
