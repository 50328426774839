import React, { useEffect, useState } from "react";
import { Testimonials } from "./testimonials";
import { graphql, useStaticQuery } from "gatsby";

export const SchoolTestimonials = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const data = useStaticQuery(graphql`
    query SchoolTestimonialsQuery {
      allGoogleSheet {
        edges {
          node {
            schooltestimonials {
              testimonialquote
              testimonialauthor
              testimonialadditional
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const edge = data?.allGoogleSheet?.edges?.[0];
    const node = edge?.node;
    const testimonials = node?.schooltestimonials;
    console.log(testimonials);
    setTestimonialData(testimonials);
  }, [data]);

  if (!data) return null;

  return <Testimonials testionals={testimonialData} />;
};
