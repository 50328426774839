import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { HomePageContentWrapper } from "../../pages";

const fields = [
  {
    type: "richText",
    name: "copy",
  },
];

export const StoryAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [displayData, setDisplayData] = useState();
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query storyQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            story {
              title
              heroimage
              copy
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { story } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(story);
    setDisplayData(story?.[0]?.copy);
    const richTextField = fields[0];
    richTextField["defaultValue"] = story[0].copy;
  }, [data]);

  const handleNew = ({ copy }) => {
    setDisplayData(copy);
    setCombinedData([
      {
        ...combinedData[0],
        copy,
      },
    ]);
  };

  useEffect(() => {
    console.log(combinedData);
  }, [combinedData]);

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    console.log(combinedData);
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      adminUpload("story", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  return (
    <>
      <h1>Our Story So Far</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      {fields?.[0]?.["defaultValue"] && (
        <FormBuilder fields={fields} onSubmit={handleNew} />
      )}
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <HomePageContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: displayData }} />
        </HomePageContentWrapper>
      </Container>
    </>
  );
};
