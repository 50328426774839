const uploadData = (sheet, inputs) => {
  return fetch(`https://sheetdb.io/api/v1/tgf1e6zqolkc3?sheet=${sheet}`, {
    method: "POST",
    body: JSON.stringify({
      data: inputs,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
};
const deleteData = (sheet, inputs) => {
  return fetch(`https://sheetdb.io/api/v1/tgf1e6zqolkc3/all?sheet=${sheet}`, {
    method: "DELETE",
    body: JSON.stringify({
      data: inputs,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
};

export const adminUpload = (sheet, inputs) => {
  deleteData(sheet, inputs);
  uploadData(sheet, inputs);
};
