import React, { useState, useEffect } from "react";
import { Button, Form, Select } from "semantic-ui-react";
import UploadImageToS3WithReactS3 from "./fileS3Uploader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RichText } from "./RichText";

export const FormBuilder = ({ fields, onSubmit }) => {
  const [inputs, setInputs] = useState({});
  const [richTextInput, setRichTextInput] = useState();

  const handleInput = (e) => {
    const textTypes = ["text", "textarea"];
    if (textTypes.includes(e.target.type)) {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleRichTextInput = (data, name) => {
    setRichTextInput({ ...richTextInput, [name]: data });
  };

  useEffect(() => {
    console.log({ ...inputs, ...richTextInput });
  }, [inputs, richTextInput]);

  const handleSelectInput = (e, data) => {
    setInputs({
      ...inputs,
      [data.name]: data.value,
    });
  };

  const handleSetDate = (date, name) => {
    setInputs({
      ...inputs,
      [name]: date?.toDateString(),
    });
  };

  const setImageUrl = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...inputs, ...richTextInput });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {fields.map(
          (
            { type, label, placeholder, name, options, defaultValue, height },
            i
          ) => (
            <Form.Field key={i}>
              {type === "text" && (
                <>
                  <label>{label}</label>
                  <input
                    placeholder={placeholder}
                    name={name}
                    type={type}
                    onChange={handleInput}
                  />
                </>
              )}
              {type === "richText" && (
                <>
                  <label>{label}</label>
                  <RichText
                    onChange={(data) => handleRichTextInput(data, name)}
                    defaultValue={defaultValue}
                    height={height || "400px"}
                  />
                </>
              )}
              {type === "file" && (
                <>
                  <label>{label}</label>
                  <UploadImageToS3WithReactS3
                    name={name}
                    onUpload={setImageUrl}
                  />
                </>
              )}
              {type === "select" && (
                <>
                  <label>{label}</label>
                  <Select
                    name={name}
                    placeholder={placeholder}
                    options={options}
                    onChange={handleSelectInput}
                  />
                </>
              )}
              {type === "date" && (
                <>
                  <label>{label}</label>
                  <DatePicker
                    selected={Date.parse(inputs?.[name])}
                    onChange={(date) => handleSetDate(date, name)}
                  />
                </>
              )}
            </Form.Field>
          )
        )}
        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
};
