import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button, Card, Icon } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import styled from "styled-components";

const fields = [
  {
    type: "file",
    label: "Upload partner logo",
    name: "imageUrl",
  },
  {
    type: "text",
    label: "Partner name",
    placeholder: "Partner",
    name: "partner",
  },
];

export const AdminPartners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedPartners, setUploadedPartners] = useState([]);

  const data = useStaticQuery(graphql`
    query adminPartnerQuery {
      allGooglePartnersSheet {
        edges {
          node {
            url
            partner
          }
        }
      }
    }
  `);

  useEffect(() => {
    setPartners(data?.allGooglePartnersSheet?.edges);
  }, [data]);

  const handleNewPartner = ({ imageUrl, partner }) => {
    if (imageUrl && partner) {
      setUploadedPartners([
        ...uploadedPartners,
        {
          node: {
            url: imageUrl,
            partner,
          },
        },
      ]);
    }
  };

  const handleRemovePartner = (url, array) => {
    if (array === "uploaded") {
      const array = uploadedPartners;
      setUploadedPartners(array.filter((item) => item.node.url !== url));
    }
    if (array === "partners") {
      const array = partners;
      setPartners(array.filter((item) => item.node.url !== url));
    }
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const allPartners = [...partners, ...uploadedPartners];
    const partnersToUpload = allPartners.map((partner) => {
      return {
        url: partner.node.url,
        partner: partner.node.partner,
      };
    });
    adminUpload("partners", partnersToUpload);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const PartnerCard = ({ url, partner, uploadedPartners }) => (
    <Container width="200px">
      <Card>
        <Container height="200px" center>
          <img src={url} alt={partner} width="200" />
        </Container>
        <Card.Content>
          <p>{partner}</p>
          <Container width="40px">
            <Button
              icon
              color="red"
              onClick={() =>
                handleRemovePartner(
                  url,
                  uploadedPartners ? "uploaded" : "partners"
                )
              }
            >
              <Icon name="close" />
            </Button>
          </Container>
        </Card.Content>
      </Card>
    </Container>
  );

  const PartnerGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  `;

  return (
    <>
      <h1>Partners</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNewPartner} />
      <Spacer height={32} />
      {!!uploadedPartners.length && (
        <>
          <h2>Uploaded Partner Logos</h2>
          <Spacer height={32} />
          <Container>
            <PartnerGrid>
              {uploadedPartners.map(({ node: { url, partner } }, i) => (
                <PartnerCard url={url} partner={partner} uploadedPartners />
              ))}
            </PartnerGrid>
          </Container>
        </>
      )}
      <h2>Published Partner Logos</h2>
      <Container>
        <PartnerGrid>
          {partners.map(({ node: { url, partner } }, i) => (
            <PartnerCard url={url} partner={partner} />
          ))}
        </PartnerGrid>
      </Container>
    </>
  );
};
