import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Spacer } from "ui-start";
import { HeroImage } from "../components/heroImage";
import Layout from "../components/layout";
import { SchoolTestimonials } from "../components/schoolTestimonials";
import { StyledPageCopy, Wrapper } from "../components/styles";
import { primaryColors } from "../styles/colours";

export const BulletPointWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  i {
    color: ${primaryColors.paleGreen} !important;
  }

  p {
    margin-left: 4px;
    padding-top: 4px;
  }
`;

export const TestimonialWrapper = styled.div``;

export const StyledBlockQuote = styled.blockquote`
  text-align: left;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  & ::before {
    content: "\\201C";
    font-size: 60px;
    color: ${primaryColors.paleGreen};
    font-family: Georgia, serif;
    position: absolute;
    top: 0;
    left: -24px;
  }
  & cite {
    color: ${primaryColors.paleGreen};
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }
  & .author {
    color: #999999;
    ::before {
      content: "\\2014 \\2009";
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const School = () => {
  const data = useStaticQuery(graphql`
    query SchoolPageQuery {
      allGoogleSheet {
        edges {
          node {
            school {
              copy01
            }
          }
        }
      }
    }
  `);

  const schoolData = data?.allGoogleSheet?.edges?.[0]?.node?.school ?? [];
  const pageData = schoolData[0] ?? null;

  return (
    <Layout>
      <HeroImage page="school" />
      <Wrapper>
        <Spacer height={32} />
        <StyledPageCopy
          dangerouslySetInnerHTML={{ __html: pageData?.copy01 }}
        />
        <SchoolTestimonials />
      </Wrapper>
    </Layout>
  );
};

export default School;
