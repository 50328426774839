import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Spacer } from "ui-start";
import { Button, Icon, Table } from "semantic-ui-react";
import { adminUpload } from "../../utilities/adminUpload";
import { FormBuilder } from "../formBuilder";

const fields = [
  {
    type: "select",
    label: "Select type of event",
    placeholder: "Select type of event",
    name: "eventType",
    options: [
      {
        key: "getWorkReadyMini",
        value: "getWorkReadyMini",
        text: "Get Work Ready (Mini)",
      },
      { key: "getWorkReady", value: "getWorkReady", text: "Get Work Ready" },
      { key: "themedDays", value: "themedDays", text: "Themed Days" },
    ],
  },
  {
    type: "date",
    label: "Date",
    placeholder: "Date",
    name: "date",
  },
  {
    type: "text",
    label: "School",
    placeholder: "School",
    name: "school",
  },
  {
    type: "text",
    label: "Year",
    placeholder: "Year",
    name: "year",
  },
];

export const EventsPartners = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedEvents, setUploadedEvents] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);

  const data = useStaticQuery(graphql`
    query adminEventQuery {
      allGoogleEventListSheet {
        edges {
          node {
            eventType
            date
            school
            year
          }
        }
      }
    }
  `);

  useEffect(() => {
    const publishedEvents = data?.allGoogleEventListSheet?.edges?.map(
      ({ node: { eventType, date, school, weekDay, year } }) => {
        return {
          eventType,
          date,
          school,
          weekDay,
          year,
        };
      }
    );
    setEvents(
      publishedEvents.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      })
    );
  }, [data]);

  const handleNewEvent = ({ date, eventType, school, weekDay, year }) => {
    setUploadedEvents([
      ...uploadedEvents,
      {
        eventType,
        date,
        school,
        weekDay,
        year,
      },
    ]);
  };

  const handleDeleteEvent = (i, uploaded) => {
    if (uploaded) {
      const eventArray = uploadedEvents.filter((el, index) => index !== i);
      setUploadedEvents(eventArray);
    } else {
      const eventArray = events.filter((el, index) => index !== i);
      setEvents(eventArray);
    }
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const allEvents = [...events, ...uploadedEvents];
    adminUpload("eventList", allEvents);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    setDisplayedEvents(
      uploadedEvents.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      })
    );
  }, [uploadedEvents]);

  const DisplayTable = ({ events, uploaded }) => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Event Type</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>School or Educational Setting</Table.HeaderCell>
          <Table.HeaderCell>Year Group/s</Table.HeaderCell>
          <Table.HeaderCell>Remove</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {events?.map(({ date, eventType, school, year }, i) => (
          <Table.Row key={i}>
            <Table.Cell>{eventType}</Table.Cell>
            <Table.Cell>{date}</Table.Cell>
            <Table.Cell>{school}</Table.Cell>
            <Table.Cell>{year}</Table.Cell>
            <Table.Cell>
              <Button
                icon
                color="red"
                onClick={() => handleDeleteEvent(i, uploaded)}
              >
                <Icon name="trash" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <h1>Events</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <h2>Add new event</h2>
      <FormBuilder fields={fields} onSubmit={handleNewEvent} />
      <Spacer height={32} />
      <h2>Uploaded Events</h2>
      <DisplayTable events={displayedEvents} uploaded />
      <h2>Published Events</h2>
      <DisplayTable events={events} />
    </>
  );
};
