import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { HomePageContentWrapper } from "../../pages";
import { DirectorImageWrapper, DirectorsLayout, StyledPageCopy, StyledPageSubHeading } from "../styles";
import { insertElementAtIndex } from "../../utilities/insertElementAtIndex";
import { removeArrayElement } from "../../utilities/removeElementFromArray";

const fields = [
  {
    type: "file",
    label: "Upload director image",
    name: "imageUrl",
  },
  {
    type: "text",
    name: "director",
    label: "Director",
  },
  {
    type: "richText",
    name: "copy",
    label: "Copy",
  },
  {
    type: "text",
    name: "insert",
    label: "Insert into position",
  },
];

export const DirectorsAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [displayData] = useState();
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query directorsQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            directors {
              director
              copy
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { directors } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(directors);
  }, [data]);

  const handleNew = ({ imageUrl, director, copy, insert }) => {
    const newData = {
      director,
      copy,
      url: imageUrl
    };
    if (insert) {
      const data = insertElementAtIndex(
        combinedData,
        Number(insert) - 1,
        newData
      );
      setCombinedData(data);
    } else {
      setCombinedData([
        ...combinedData,
        {
          director,
          copy,
          url: imageUrl
        },
      ]);
    }
  };

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      adminUpload("directors", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  const handleRemove = (index) => {
    setCombinedData(removeArrayElement(combinedData, index));
  };

  return (
    <>
      <h1>Our Directors</h1>
      <Spacer height={32} />
      <h3>Add a new director</h3>
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNew} />
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <HomePageContentWrapper>
          {combinedData?.map(({ director, copy, url }, i) => (
            <>
              <p>Position: {i + 1}</p>
              <button onClick={() => handleRemove(i)}>Delete</button>
              <>
              <DirectorsLayout>
              <div>
               <StyledPageSubHeading>{director}</StyledPageSubHeading>
                <DirectorImageWrapper>
                <img src={url} alt={director} />
                </DirectorImageWrapper>
              </div>
              <StyledPageCopy dangerouslySetInnerHTML={{ __html: copy }} />
            </DirectorsLayout>
              {i !== combinedData.length - 1 &&
              <>
                <Spacer height={48} />
                <div style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#e5e5e5",
                }} />
              </>}
              </>
            </>
          ))}
          <div dangerouslySetInnerHTML={{ __html: displayData }} />
        </HomePageContentWrapper>
      </Container>
    </>
  );
};
