import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { StyledPageCopy } from "../styles";

const fields = [
  {
    type: "richText",
    name: "copy01",
  },
];

export const VolunteerAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [displayData, setDisplayData] = useState();
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query volunteerQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            volunteer {
              copy01
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { volunteer } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(volunteer);
    setDisplayData(volunteer?.[0]?.copy01);
    const richTextField = fields[0];
    richTextField["defaultValue"] = volunteer[0].copy01;
  }, [data]);

  const handleNew = ({ copy01 }) => {
    if (copy01) {
      setDisplayData(copy01);
      setCombinedData([
        {
          ...combinedData[0],
          copy01,
        },
      ]);
    }
  };

  useEffect(() => {
    console.log(combinedData);
  }, [combinedData]);

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      adminUpload("volunteer", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  return (
    <>
      <h1>Volunteer</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      {fields?.[0]?.["defaultValue"] && (
        <FormBuilder fields={fields} onSubmit={handleNew} />
      )}
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <StyledPageCopy dangerouslySetInnerHTML={{ __html: displayData }} />
      </Container>
    </>
  );
};
