import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Container, Spacer } from "ui-start";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import { Grid } from "../components/grid";
import Layout from "../components/layout";
import {
  StyledPageCopy,
  StyledPageSubHeading,
  Wrapper,
} from "../components/styles";
import { primaryColors } from "../styles/colours";
import { HeroImage } from "../components/heroImage";

export const StylesCasesWrapper = styled.div`
  & p > blockquote {
    text-align: left;
    position: relative;
    font-size: 18px;
    line-height: 24px;
  }

  & blockquote ::before {
    content: "\\201C";
    font-size: 60px;
    color: ${primaryColors.paleGreen};
    font-family: Georgia, serif;
    position: absolute;
    top: 0;
    left: -24px;
  }
  & span {
    color: ${primaryColors.violet};
  }
`;

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query casestudiesPageQuery {
      allGoogleSheet {
        edges {
          node {
            casestudies {
              casetitle
              copy
              images01
              images02
              images03
              images04
            }
          }
        }
      }
    }
  `);

  const cases = data?.allGoogleSheet?.edges?.[0]?.node?.casestudies;
  const pageData = cases?.[0];

  return (
    <Layout>
      <HeroImage page="casestudies" />
      <Wrapper>
        <Spacer height={32} />
        {cases.map(
          ({
            casetitle,
            copy,
            images01,
            images02,
            images03,
            images04
          }) => (
            <div key={casetitle}>
              <StylesCasesWrapper>
                <StyledPageSubHeading>{casetitle}</StyledPageSubHeading>
                <StyledPageCopy dangerouslySetInnerHTML={{ __html: copy }} />
              </StylesCasesWrapper>
              <Spacer height={32} />
              <Container center width="100%">
                {images01 && (
                  <>
                  <Grid columns={4} columnsMobile={1} columnsTablet={3}>
                    <Container>
                      <img src={images01} alt="case study" width="100%" />
                    </Container>
                    {images02 &&
                    <Container>
                      <img src={images02} alt="case study" width="100%" />
                    </Container>}
                    {images03 &&
                    <Container>
                      <img src={images03} alt="case study" width="100%" />
                    </Container>}
                    {images04 &&
                    <Container>
                      <img src={images04} alt="case study" width="100%" />
                    </Container>}
                  </Grid>
                  <Spacer height={16} />
                  </>
                )}
              </Container>
            </div>
          )
        )}
        <Spacer height={32} />
      </Wrapper>
    </Layout>
  );
};

export default CaseStudies;
