import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { insertElementAtIndex } from "../../utilities/insertElementAtIndex";
import { removeArrayElement } from "../../utilities/removeElementFromArray";
import { Testimonials } from "../testimonials";

const fields = [
  {
    type: "richText",
    name: "testimonialquote",
    label: "Testimonial Quote",
    height: "150px",
  },
  {
    type: "text",
    name: "testimonialauthor",
    label: "Testimonial Author",
  },
  {
    type: "text",
    name: "testimonialadditional",
    label: "Testimonial Additional Information",
  },
  {
    type: "text",
    name: "insert",
    label: "Insert into position",
  },
];

export const BusinessTestimonialsAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query businessTestimonialsQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            businesstestimonials {
              testimonialquote
              testimonialauthor
              testimonialadditional
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { businesstestimonials } =
      data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(businesstestimonials);
  }, [data]);

  const handleNew = ({
    testimonialquote,
    testimonialauthor,
    testimonialadditional,
    insert,
  }) => {
    const newData = {
      testimonialquote,
      testimonialauthor,
      testimonialadditional: testimonialadditional || "",
    };

    if (Object.values(newData).some(Boolean)) {
      if (insert) {
        const data = insertElementAtIndex(
          combinedData,
          Number(insert) - 1,
          newData
        );
        setCombinedData(data);
      } else {
        setCombinedData([...combinedData, newData]);
      }
    }
  };

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      adminUpload("businesstestimonials", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  const handleRemove = (index) => {
    setCombinedData(removeArrayElement(combinedData, index));
  };

  return (
    <>
      <h1>Business Testimonials</h1>
      <Spacer height={32} />
      <h3>Add a new testimonial</h3>
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNew} />
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <Testimonials
          testionals={combinedData}
          admin
          handleRemove={handleRemove}
        />
      </Container>
    </>
  );
};
