import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { Spacer } from "ui-start";
import { publishNewSite } from "../utilities/buildHook";
import { BusinessAdmin } from "./admin/business";
import { BusinessTestimonialsAdmin } from "./admin/businessTestimonials";
import { DirectorsAdmin } from "./admin/directors";
import { EventsPartners } from "./admin/events";
import { Guides } from "./admin/guides";
import { HomeAdmin } from "./admin/home";
import { Newsletters } from "./admin/newsletters";
import { AdminPartners } from "./admin/partners";
import { AdminProgrammes } from "./admin/programmes";
import { SchoolAdmin } from "./admin/school";
import { SchoolTestimonialsAdmin } from "./admin/schoolTestimonials";
import { StoryAdmin } from "./admin/story";
import { VolunteerAdmin } from "./admin/volunteer";
import { VolunteerTestimonialsAdmin } from "./admin/volunteerTestimonials";
import { CaseStudiesAdmin } from "./admin/case-studies";
import { PageTitlesAdmin } from "./admin/page-titles";

export const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 100px;
`;

export const MenuPanel = styled.div`
  position: fixed;
  top: 100px;
  background: white;
  height: 100vh;
  width: 300px;
  border-right: solid 1px #333;
  padding: 16px;
  & div {
    cursor: pointer;
  }
`;

export const MenuDisplay = styled.div`
  padding: 16px;
  margin-top: 60px;
  margin-left: 300px;
`;

export const PublishWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 100;
  border-bottom: solid 1px;
  align-items: left;
  justify-content: flex-end;
  padding: 24px;
  `;
  
  const menuItems = [
    {
      label: "Guides",
      name: "guides",
    },
    {
      label: "Page Titles",
      name: "page-titles",
    },
    {
      label: "Partner Logos",
      name: "partners",
    },
    {
      label: "Events",
      name: "events",
    },
    {
      label: "Newsletters",
      name: "newsletters",
    },
    {
      label: "Programmes",
      name: "programmes",
    },
    {
      label: "Home",
      name: "home",
    },
    {
      label: "School",
      name: "school",
    },
    {
      label: "School Testimonials",
      name: "school-testimonials",
    },
    {
      label: "Business",
      name: "business",
    },
    {
      label: "Business Testimonials",
      name: "business-testimonials",
    },
    {
      label: "Volunteer",
      name: "volunteer",
    },
    {
      label: "Volunteer Testimonials",
      name: "volunteer-testimonials",
    },
    {
      label: "Our story so far",
      name: "story",
    },
    {
      label: "Directors",
      name: "directors",
    },
    {
      label: "Case Studies (Success Stories)",
      name: "case-studies",
    },
  ];

export const AdminPanel = () => {
  const [selected, setSelected] = useState("partners");
  const [publishing, setPublishing] = useState(false);
  const handleMenuClick = (name) => {
    setSelected(name);
  };

  const handlePublish = () => {
    setPublishing(true);
    publishNewSite();
    setTimeout(() => {
      setPublishing(false);
    }, 3000);
  };
  return (
    <PanelWrapper>
      <>
        <PublishWrapper>
          <Button primary onClick={handlePublish}>
            Publish All Changes
          </Button>
          {publishing && (
            <p>
              Publishing changes - please allow 5 minutes for site to be updated
            </p>
          )}
        </PublishWrapper>
        <MenuPanel>
          <h2>Elsa Admin Panel</h2>
          {menuItems.map(({ label, name }, i) => (
            <div
              key={i}
              onClick={() => handleMenuClick(name)}
              aria-hidden="true"
            >
              <h3>{label}</h3>
              <Spacer height={16} />
            </div>
          ))}
        </MenuPanel>
        <MenuDisplay>
          {selected === "partners" && <AdminPartners />}
          {selected === "events" && <EventsPartners />}
          {selected === "newsletters" && <Newsletters />}
          {selected === "guides" && <Guides />}
          {selected === "programmes" && <AdminProgrammes />}
          {selected === "home" && <HomeAdmin />}
          {selected === "school" && <SchoolAdmin />}
          {selected === "school-testimonials" && <SchoolTestimonialsAdmin />}
          {selected === "business-testimonials" && (
            <BusinessTestimonialsAdmin />
          )}
          {selected === "business" && <BusinessAdmin />}
          {selected === "volunteer" && <VolunteerAdmin />}
          {selected === "volunteer-testimonials" && (
            <VolunteerTestimonialsAdmin />
          )}
          {selected === "story" && <StoryAdmin />}
          {selected === "directors" && <DirectorsAdmin />}
          {selected === "case-studies" && <CaseStudiesAdmin />}
          {selected === "page-titles" && <PageTitlesAdmin />}
        </MenuDisplay>
      </>
    </PanelWrapper>
  );
};
