import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { removeArrayElement } from "../../utilities/removeElementFromArray";
import { HeroImage } from "../heroImage";
import { update } from "ramda";

const fields = [
  {
    type: "text",
    name: "title",
    label: "Update page title",
  },
  {
    type: "file",
    label: "Update image",
    name: "imageUrl",
  },
];
const fields1 = [
  {
    type: "select",
    label: "Select page",
    placeholder: "page",
    name: "selection",
    options: [
      {
        key: "home",
        value: "home",
        text: "Home Page",
      },
      {
        key: "directors",
        value: "directors",
        text: "Our Team Page",
      },
      {
        key: "school",
        value: "school",
        text: "Schools Page",
      },
      {
        key: "busines",
        value: "business",
        text: "Business Page",
      },
      {
        key: "volunteer",
        value: "volunteer",
        text: "Volunteer Page",
      },
      {
        key: "feedback",
        value: "feedback",
        text: "Volunteer Feedback Page",
      },
      {
        key: "events",
        value: "events",
        text: "Our Events Page",
      },
      {
        key: "contact-us",
        value: "contact-us",
        text: "Contact Us Page",
      },
      {
        key: "newsletter",
        value: "newsletter",
        text: "Newsletter Page",
      },
      {
        key: "story",
        value: "story",
        text: "Our Story Page",
      },
      {
        key: "casestudies",
        value: "casestudies",
        text: "Case Studies Page",
      },
    ],
  },
];

export const PageTitlesAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [displayData] = useState();
  const [combinedData, setCombinedData] = useState([]);
  const [pageType, setPageType] = useState("");
  const [pageTypeDisplay, setPageTypeDisplay] = useState("");

  const data = useStaticQuery(graphql`
    query pageTitlesQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            heroimages {
              page
              imageurl
              title
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { heroimages } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    setCombinedData(heroimages);
  }, [data]);

  const handlePageSelect = ({ selection }) => {
    setPageType(selection)
    setPageTypeDisplay("")
  }

  useEffect(() => {
    console.log(combinedData)
  }, [combinedData])

  const handleNew = ({ imageUrl, title }) => {
    const index = combinedData.find((item) => item.page === pageType);

    const newData = {
      ...index,
      imageurl: imageUrl || index.imageurl,
      title: title || index.title,
    };

    setPageTypeDisplay(newData.title)
    
    const updatedArray = combinedData.filter((item) => item.page !== pageType);
    
    setCombinedData([
      ...updatedArray,
      newData
    ]); 
  };

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      adminUpload("heroimages", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  return (
    <>
      <h1>Select a page</h1>
      <Spacer height={32} />
      <FormBuilder fields={fields1} onSubmit={handlePageSelect} />
      <h3>Update {pageType} page title and hero image</h3>
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNew} />
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        {pageType &&
          <HeroImage page={pageType} key={pageType} pageTitle={pageTypeDisplay} />
        }     
      </Container>
    </>
  );
};
