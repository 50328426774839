import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container, Spacer } from "ui-start";
import { Button } from "semantic-ui-react";
import { FormBuilder } from "../formBuilder";
import { adminUpload } from "../../utilities/adminUpload";
import { HomePageContentWrapper } from "../../pages";
import { StyledPageCopy, StyledPageSubHeading } from "../styles";
import { insertElementAtIndex } from "../../utilities/insertElementAtIndex";
import { removeArrayElement } from "../../utilities/removeElementFromArray";
import { Grid } from "../grid";
import { StylesCasesWrapper } from "../../pages/case-studies";

const fields = [
  {
    type: "text",
    name: "casetitle",
    label: "Case Title",
  },
  {
    type: "richText",
    name: "copy",
    label: "Copy",
  },
  {
    type: "file",
    label: "Upload image 01",
    name: "images01",
  },
  {
    type: "file",
    label: "Upload image 02",
    name: "images02",
  },
  {
    type: "file",
    label: "Upload image 03",
    name: "images03",
  },
  {
    type: "file",
    label: "Upload image 04",
    name: "images04",
  },
  {
    type: "text",
    name: "insert",
    label: "Insert into position",
  },
];

export const CaseStudiesAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [displayData] = useState();
  const [combinedData, setCombinedData] = useState([]);

  const data = useStaticQuery(graphql`
    query caseStudiesQueryAdmin {
      allGoogleSheet {
        edges {
          node {
            casestudies {
              casetitle
              copy
              images01
              images02
              images03
              images04
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { casestudies } = data?.allGoogleSheet?.edges?.[0]?.node || [];
    console.log(casestudies)
    setCombinedData(casestudies);
  }, [data]);

  const handleNew = ({ casetitle, copy, images01, images02, images03, images04, insert }) => {
    const newData = {
      casetitle,
      copy,
      images01,
      images02,
      images03,
      images04,
    };
    console.log(newData)
    if (insert) {
      const data = insertElementAtIndex(
        combinedData,
        Number(insert) - 1,
        newData
      );
      setCombinedData(data);
    } else {
      setCombinedData([
        ...combinedData,
        {
          casetitle,
          copy,
          images01,
          images02,
          images03,
          images04,
        },
      ]);
    }
  };

  useEffect(() => {
    console.log(combinedData)
  }, [combinedData])

  const handleSaveChanges = () => {
    setLoading(true);
    uploadData(combinedData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  function uploadData(combinedData) {
    const allKeysHaveValues = Object.values(combinedData).every(
      (val) => val !== undefined && val !== null && val !== ""
    );
    if (allKeysHaveValues) {
      console.log(combinedData)
      adminUpload("casestudies", combinedData);
    } else {
      console.error(
        "Not all keys in combinedData have values. Aborting upload."
      );
    }
  }

  const handleRemove = (index) => {
    setCombinedData(removeArrayElement(combinedData, index));
  };

  return (
    <>
      <h1>Success Stories</h1>
      <Spacer height={32} />
      <h3>Add a new case study</h3>
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNew} />
      <Spacer height={32} />

      <h2>Preview</h2>
      <Container>
        <HomePageContentWrapper>
        {combinedData?.map(
          ({
            casetitle,
            copy,
            images01,
            images02,
            images03,
            images04,
          }, i) => (
            <>
            <p>Position: {i + 1}</p>
              <button onClick={() => handleRemove(i)}>Delete</button>
            <div key={casetitle}>
              <StylesCasesWrapper>
                <StyledPageSubHeading>{casetitle}</StyledPageSubHeading>
                <StyledPageCopy dangerouslySetInnerHTML={{ __html: copy }} />
              </StylesCasesWrapper>
              <Spacer height={32} />
              <Container center width="100%">
                {images01 && (
                  <>
                    <Grid columns={4} columnsMobile={1} columnsTablet={3}>
                      <Container>
                        <img src={images01} alt="case study" width="100%" />
                      </Container>
                      {images02 &&
                      <Container>
                        <img src={images02} alt="case study" width="100%" />
                      </Container>}
                      {images03 &&
                      <Container>
                        <img src={images03} alt="case study" width="100%" />
                      </Container>}
                      {images04 &&
                      <Container>
                        <img src={images04} alt="case study" width="100%" />
                      </Container>}
                    </Grid>
                    <Spacer height={16} />
                  </>
                )}
              </Container>
            </div>
            </>
          )
        )}
        </HomePageContentWrapper>
      </Container>
    </>
  );
};
