import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Spacer } from "ui-start";
import { AdminPanel } from "../components/adminPanel";
import { ErrorMessage } from "../components/styles";

const Login = ({ onSubmit, error }) => (
  <Form onSubmit={onSubmit}>
    <Form.Field>
      <label htmlFor="code">Enter access code</label>
      <input type="text" name="code" placeholder="Enter access code" />
    </Form.Field>
    <Spacer height={16} />
    <Button type="submit">Submit</Button>
    {error && <ErrorMessage>Incorrect code</ErrorMessage>}
  </Form>
);

const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    const formFields = e.target.elements;
    if (formFields.code.value === process.env.GATSBY_ADMIN_ACCESS_CODE) {
      setLoggedIn(true);
    } else {
      setError(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      {!loggedIn && <Login onSubmit={handleSubmit} error={error} />}
      {loggedIn && <AdminPanel />}
    </div>
  );
};

export default Admin;
