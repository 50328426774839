import React, { useState } from "react";
import { uploadFile } from "react-s3";
import { Dimmer, Loader } from "semantic-ui-react";

const S3_BUCKET = "elsa-website-assets";
const REGION = "us-east-1";
const ACCESS_KEY = `${process.env.GATSBY_ACCESS_KEY}`;
const SECRET_ACCESS_KEY = `${process.env.GATSBY_SECRET_ACCESS_KEY}`;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadImageToS3WithReactS3 = ({ name, onUpload, label }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleFileInput = (e) => {
    setError(false);
    const file = e.target.files[0];
    setLoading(true);
    uploadFile(file, config)
      .then((data) => {
        onUpload(name, data?.location);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div>
      <div>{label}</div>
      <input type="file" onChange={handleFileInput} />
      {error && (
        <p color="red">
          File could not be uploaded - check your internet connection and try
          again
        </p>
      )}
      {loading && (
        <div>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </div>
      )}
    </div>
  );
};

export default UploadImageToS3WithReactS3;
