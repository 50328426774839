import React from "react";
import { Container, Spacer } from "ui-start";
import { TestimonialWrapper, StyledBlockQuote } from "../pages/school";
import { StyledPageSubHeading } from "./styles";

export const Testimonials = ({ testionals, admin, handleRemove }) => {
  return (
    <Container>
      <StyledPageSubHeading>Testimonials</StyledPageSubHeading>
      <Spacer height={16} />
      {testionals.map(
        ({ testimonialquote, testimonialauthor, testimonialadditional }, i) => (
          <div key={i}>
            {admin && (
              <>
                <p>Position: {i + 1}</p>
                <button onClick={() => handleRemove(i)}>Delete</button>
              </>
            )}
            <TestimonialWrapper>
              <StyledBlockQuote>
                <span dangerouslySetInnerHTML={{ __html: testimonialquote }} />
                <cite className="author">{testimonialauthor}</cite>
                <cite>{testimonialadditional}</cite>
              </StyledBlockQuote>
            </TestimonialWrapper>
            <Spacer height={32} />
          </div>
        )
      )}
    </Container>
  );
};
