import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { Spacer } from "ui-start";
import { adminUpload } from "../../utilities/adminUpload";
import { FormBuilder } from "../formBuilder";

const fields = [
  {
    type: "file",
    label: "Upload newsletter",
    name: "link",
  },
  {
    type: "text",
    label: "Newsletter title",
    placeholder: "Newsletter title",
    name: "title",
  },
];

export const Newsletters = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [uploadedNewsletters, setUploadedNewsletters] = useState([]);
  const [loading, setLoading] = useState(false);

  const data = useStaticQuery(graphql`
    query adminNewsLettersQuery {
      allGoogleNewslettersSheet {
        edges {
          node {
            link
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    const publishedNewsletters = data?.allGoogleNewslettersSheet?.edges?.map(
      ({ node: { link, title } }) => {
        return {
          link,
          title,
        };
      }
    );
    setNewsletters(publishedNewsletters);
  }, [data]);

  useEffect(() => {
    console.log(newsletters);
  }, [newsletters]);

  const handleNewPartner = ({ title, link }) => {
    if (title && link) {
      setUploadedNewsletters([
        ...uploadedNewsletters,
        {
          title,
          link,
        },
      ]);
    }
  };

  const handleDeleteEvent = (i, uploaded) => {
    if (uploaded) {
      const newslettersArray = uploadedNewsletters.filter(
        (el, index) => index !== i
      );
      setUploadedNewsletters(newslettersArray);
    } else {
      const newslettersArray = newsletters.filter((el, index) => index !== i);
      setNewsletters(newslettersArray);
    }
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const allNewsletters = [...newsletters, ...uploadedNewsletters];
    adminUpload("newsletters", allNewsletters);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const DisplayTable = ({ items, uploaded }) => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Remove</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items?.map(({ title }, i) => (
          <Table.Row key={i}>
            <Table.Cell>{title}</Table.Cell>
            <Table.Cell>
              <Button
                icon
                color="red"
                onClick={() => handleDeleteEvent(i, uploaded)}
              >
                <Icon name="trash" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <h1>Newsletters</h1>
      <Spacer height={32} />
      <>
        <Button primary onClick={handleSaveChanges}>
          Save Changes
        </Button>
        {loading && <p>Saving data - please wait</p>}
        <Spacer height={16} />
      </>
      <FormBuilder fields={fields} onSubmit={handleNewPartner} />
      <h2>Uploaded Newsletters</h2>
      <DisplayTable items={uploadedNewsletters} uploaded />
      <h2>Published Newsletters</h2>
      <DisplayTable items={newsletters} />
    </>
  );
};
