import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "suneditor/src/assets/css/suneditor.css";

export const RichText = ({ name, onChange, props, defaultValue, height }) => {
  const [starterValue, setStarterValue] = useState(defaultValue);

  console.log(defaultValue);

  useEffect(() => {
    setStarterValue(defaultValue);
  }, [defaultValue]);
  const options = {
    buttonList: [
      [
        "fontSize",
        "bold",
        "underline",
        "italic",
        "fontColor",
        "hiliteColor",
        "undo",
        "redo",
        "align",
        "list",
        "link",
        "fullScreen",
        "codeView",
        "preview",
      ],
    ],
    colorList: ["#5ac1d2", "#974b97", "#000", "#FFF"],
    fontList: ["Arial"],
  };

  return (
    <>
      <SunEditor
        {...props}
        placeholder="Please type here..."
        name={name}
        lang="en"
        height={height}
        setDefaultStyle="font-family: Arial; font-size: 14px;"
        setOptions={options}
        onChange={onChange}
        defaultValue={starterValue}
      />
    </>
  );
};
