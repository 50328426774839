const publishSite = (sheet, inputs) => {
  return fetch(`https://api.netlify.com/build_hooks/6390d61d4cd9cf567e7ef1f6`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
};

export const publishNewSite = () => {
  publishSite();
};
